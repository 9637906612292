<template>
    <div class="home">
      <v-layout row wrap class="d-flex justify-center">
        <v-flex xs12 md12 lg12 class="mb-5">
          <h1 class="f-alfa primary--text"><router-link to="/">Dashboard</router-link> / Vouchers</h1>
        </v-flex>
        <v-flex xs12 class="mb-5">
          <v-layout row wrap class="d-flex justify-space-between align-center">
            <v-flex xs8 md6 lg4>
              <v-text-field
                single-line
                outlined
                v-model="searchText"
                label="Type to search.."
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-flex>
            <v-flex xs4 md4 lg4 class="d-flex justify-end">
              <v-btn class="primary black--text" x-large @click="addVoucher">Add Voucher</v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left uppercase primary--text">#</th>
                  <th class="text-left uppercase primary--text">
                    Voucher
                  </th>
                  <th class="text-left uppercase primary--text">Discount</th>
                  <th class="text-left uppercase primary--text">Discount Type</th>
                  <th class="text-left uppercase primary--text">Voucher Type</th>
                  <th class="text-left uppercase primary--text">Valid From</th>
                  <th class="text-left uppercase primary--text">Expires In</th>
                  <th class="text-left uppercase primary--text">Expiry Date</th>
                  <th class="text-left uppercase primary--text"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in filteredVouchers" :key="item._id">
                  <td>{{ index + 1 }}</td>
                  <td class="primary--text fs-12 fw-bold">{{ item.code }}</td>
                  <td>{{ item.discount }}</td>
                  <td>{{ item.discount_type }}</td>
                  <td>{{ item.type }}</td>
                  <td>{{ item.valid_from | moment("dddd, MMMM Do YYYY") }}</td>
                  <td>{{ item.expires_in}} Days</td>
                  <td>{{ item.expiry_date | moment("dddd, MMMM Do YYYY") }}</td>
                  <td class="primary--text" >
                    <v-icon class="primary--text">mdi-delete-outline</v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
      </v-layout>
      
    </div>
  </template>
  
  
  <script>
  import Axios from "axios";
  import { BASE_URL } from "@/config";
  export default {
    data() {
      return {
        vouchers: [],
        searchText: "",
        
        token: "",
        integration: {},
      };
    },
    mounted() {
      this.fetchVouchers();
    },
    computed: {
      filteredVouchers() {
        const query = this.searchText.toLowerCase();
        if (query.length >= 3) {
          return this.vouchers.filter((item) =>
            JSON.stringify(item).toLowerCase().includes(query)
          );
        } else {
          return this.vouchers;
        }
      },
    },
    methods: {
      async fetchVouchers() {
        this.$store.dispatch("SET_PROGRESS",true)
        let url = BASE_URL + "/promotion";
        let { data } = await Axios.get(url);
        this.vouchers = data;
        this.$store.dispatch("SET_PROGRESS",false)
      },
      addVoucher(){
          this.$router.push('/promotions/add-voucher')
      },
    },
  };
  </script>
  
  <style scoped>
  .fs-12 {
    font-size: 12pt !important;
  }
  </style>